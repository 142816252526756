import { graphql } from 'gatsby'
import React from 'react'

import { StandardPageType } from '../../@import-types/contentful/StandardPage.types'
import ContentArea from '../../components/ContentArea'
import Layout from '../../components/Layout'
import MediaHero from '../../components/_heroes/MediaHero'
import ServicesHeroBgImage from '../../components/_heroes/ServicesHero/ServicesHeroBgImage'
import Colors from '../../constants/Colors'
import { RootPageQuery } from '../../graphql/global.graphql'

type StandardPageProps = RootPageQuery<{
  contentfulStandardPage: StandardPageType
}>

export default function StandardPage({ data, pageContext }: StandardPageProps) {
  const { title, heroImage, heroRichText, content, heroPoster } =
    data.contentfulStandardPage

  return (
    <Layout
      data={data}
      basePageContent={data.contentfulStandardPage}
      pageContext={pageContext}
      pageBackgroundColor={Colors.GreenDark}
      pageTextColor={Colors.White}
    >
      {title && (
        <MediaHero
          title={title}
          heroRichText={heroRichText}
          poster={heroPoster}
          media={heroImage}
        />
      )}
      {content && <ContentArea content={content} />}
    </Layout>
  )
}

export const pageQuery = graphql`
  query StandardPageById(
    $id: String!
    $startPageId: String!
    $locale: String!
  ) {
    contentfulStandardPage(id: { eq: $id }) {
      ...BasePageFragment

      title

      heroRichText {
        json
      }

      heroImage {
        title
        description
        file {
          contentType
          url
        }
        fluid(quality: 90, maxWidth: 1400) {
          ...GatsbyContentfulFluid_withWebp
        }
      }

      heroPoster {
        title
        description
        fluid(quality: 90, maxWidth: 1400) {
          ...GatsbyContentfulFluid_withWebp
        }
      }

      content {
        __typename

        ... on Node {
          ...ContentAreaFragment
        }
      }
    }

    ...GlobalFragment
  }
`
