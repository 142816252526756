import classNames from 'classnames'
import Img from 'gatsby-image'
import React, { useEffect, useRef } from 'react'
import { useInView } from 'react-intersection-observer'

import { ContentfulAsset } from '../../../@import-types/contentful/Asset.types'
import { PageColorDataProps } from '../../../constants/GlobalDOM'
import useWindowSize from '../../../hooks/useWindowSize'
import ContentfulRichText from '../../_elements/ContentfulRichText'
import LayoutContainer from '../../_layouts/LayoutContainer'
import RichTextLayout from '../../_layouts/RichTextLayout'
import HeroHeading from '../../_typography/HeroHeading'

import styles from './media-hero.module.scss'

interface MediaHeroProps {
  title: string | null | undefined
  heroRichText?: { json: any } | null
  media: ContentfulAsset | null | undefined
  className?: string
  poster?: ContentfulAsset | null | undefined
}

export default function MediaHero({
  title,
  heroRichText,
  media,
  className,
  poster,
}: MediaHeroProps) {
  useWindowSize()

  const videoRef = useRef<HTMLVideoElement>(null)
  const { ref, inView } = useInView({
    initialInView: true,
    threshold: 0.25,
  })

  const isVideo = media?.file?.contentType?.includes('video')
  const isImage = media?.file?.contentType?.includes('image')

  useEffect(() => {
    if (inView) {
      videoRef?.current?.play()
    } else {
      videoRef?.current?.pause()
    }
  }, [inView])

  return (
    <div
      ref={ref}
      className={classNames(styles.containerBg, className)}
      {...PageColorDataProps}
    >
      {isImage && media && media.fluid && (
        <Img
          className={styles.posterImage}
          fluid={media.fluid}
          alt={media.description || media.title || ''}
        />
      )}
      {isVideo && media && (
        <>
          {poster?.fluid && (
            <Img
              className={styles.posterImage}
              fluid={poster.fluid}
              alt={media.description || media.title || ''}
            />
          )}
          <video
            ref={videoRef}
            className={styles.heroVideo}
            id="videoPlayer"
            src={media.file?.url}
            title={media.description || media.title || ''}
            autoPlay
            preload={'none'}
            muted
            loop
          />
        </>
      )}
      <LayoutContainer className={styles.textsContainer}>
        <HeroHeading
          className={styles.title}
          dangerouslySetInnerHTML={{ __html: title ?? '' }}
        ></HeroHeading>

        {heroRichText?.json && (
          <RichTextLayout className={styles.text}>
            <ContentfulRichText json={heroRichText.json} />
          </RichTextLayout>
        )}
      </LayoutContainer>
    </div>
  )
}
